import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';

import useBreakpoint from '../hooks/breakpoint';

import AgendaFilter from '../components/AgendaFilter';
import PageHeader from '../components/PageHeader';

import Maps from '../components/Maps';

const options = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
};

export default function Agenda() {
  const [data, setData] = useState([]);

  const [openFilters, setOpenFilters] = useState(false);
  const [showMaps, setShowMaps] = useState(false);

  const [city, setCity] = useState({});
  const [distance, setDistance] = useState(0);
  const [endDate, setEndDate] = useState('');
  const [startDate, setStartDate] = useState('');
  const [query, setQuery] = useState('');

  const { isSmallScreen } = useBreakpoint();

  useEffect(() => {
    const lon = city.longitude ? `lon=${city.longitude}` : '';
    const lat = city.latitude ? `lat=${city.latitude}` : '';
    const parameters = lon && lat ? `${lon}&${lat}&name=${city.name}&state=${city.state}&distance=${distance}` : '';

    fetch(`/api/v1/events?${parameters}`)
      .then((res) => res.json())
      .then((data) => setData(data));
  }, [city, distance]);

  const filterDateFn = (item) => {
    if (startDate.length > 0 || endDate.length > 0) {
      return (
        (startDate.length === 0 ||
          new Date(startDate).setHours(0, 0, 0, 0) <= new Date(item.start_date).setHours(0, 0, 0, 0)) &&
        (endDate.length === 0 || new Date(endDate).setHours(0, 0, 0, 0) >= new Date(item.end_date).setHours(0, 0, 0, 0))
      );
    } else {
      return true;
    }
  };

  const filterQueryFn = (item) => {
    const queryScope = `${item.description} ${item.guide.practice}`;

    if (query.length > 0) {
      return queryScope.toLowerCase().includes(query.toLowerCase());
    } else {
      return true;
    }
  };

  const events = [...data].filter(filterQueryFn).filter(filterDateFn);

  const onClick = (e) => {
    e.stopPropagation();

    setOpenFilters(true);
  };

  const onClickEvent = (id) => {
    window.location.href = `/gids/${id}`;
  };

  const renderEventCard = (event) => {
    const startDate = new Date(event.start_date);

    return (
      <div key={event.id} className="card event-card">
        <div className="event-image"></div>
        <Row>
          <Col>
            <div className="event-content">
              <h5>{event.guide.practice}</h5>
              <h6>{startDate.toLocaleString('nl-NL', options)}</h6>
              <p>
                {event.description}{' '}
                <a href={`/gids/${event.guide_id}`} className="stretched-link">
                  Lees verder
                </a>
              </p>
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  const renderFilterHeader = () => (
    <div className="d-none d-md-flex filter-list">
      <AgendaFilter title="Zoek" type="search" query={query} setQuery={setQuery} />
      <AgendaFilter
        title="Locatie"
        type="location"
        city={city}
        distance={distance}
        setCity={setCity}
        setDistance={setDistance}
      />
      <AgendaFilter title="Van" type="fromDate" startDate={startDate} setStartDate={setStartDate} />
      <AgendaFilter title="Tot" type="tillDate" endDate={endDate} setEndDate={setEndDate} />
      <AgendaFilter collapse opened={false} title="Provincie" type="province" />
    </div>
  );

  const renderFilters = () => (
    <>
      <AgendaFilter title="Zoek" type="search" query={query} setQuery={setQuery} />
      <AgendaFilter
        title="Locatie"
        type="location"
        city={city}
        distance={distance}
        setCity={setCity}
        setDistance={setDistance}
      />
      <AgendaFilter title="Van" type="fromDate" startDate={startDate} setStartDate={setStartDate} />
      <AgendaFilter title="Tot" type="tillDate" endDate={endDate} setEndDate={setEndDate} />
      <AgendaFilter collapse opened={false} title="Provincie" type="province" />
    </>
  );

  return (
    <>
      <PageHeader imageClass="agenda" title="Agenda" />
      <section className="filter-section">{renderFilterHeader()}</section>
      <section className="grey-section flex-column">
        <Container>
          <Row>
            <Col className="position-relative p-0">
              <button className="btn btn-light link map-button" onClick={() => setShowMaps((x) => !x)}>
                {showMaps ? (
                  <span className="d-flex align-items-center gap-2">
                    <FontAwesomeIcon icon={icon({ name: 'list' })} />
                    <span className="d-none d-md-block">Toon lijst</span>
                  </span>
                ) : (
                  <span className="d-flex align-items-center gap-2">
                    <FontAwesomeIcon icon={icon({ name: 'map' })} />
                    <span className="d-none d-md-block">Toon kaart</span>
                  </span>
                )}
              </button>
              {showMaps ? (
                <Maps events={events} />
              ) : isSmallScreen ? (
                <Table className="agenda-table">
                  <thead>
                    <tr>
                      <th>Praktijk</th>
                      <th>Startdatum</th>
                      <th>Locatie</th>
                    </tr>
                  </thead>
                  <tbody>
                    {events.map((event) => (
                      <tr key={event.id} className="link-row" onClick={() => onClickEvent(event.guide_id)}>
                        <td>{event.guide.practice}</td>
                        <td>{new Date(event.start_date).toLocaleString('nl-NL', options)}</td>
                        <td>
                          <a href={`/gids/${event.guide_id}`}>{event.guide.city}</a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <div className="events-wrapper">{events.map(renderEventCard)}</div>
              )}
            </Col>
          </Row>
          <button className="d-sm-block d-md-none filter-button" onClick={onClick}>
            <FontAwesomeIcon icon={icon({ name: 'filter' })} />
          </button>
        </Container>
      </section>
      <Modal show={openFilters} fullscreen onHide={() => setOpenFilters(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Filters</Modal.Title>
        </Modal.Header>
        <Modal.Body>{renderFilters()}</Modal.Body>
      </Modal>
    </>
  );
}
